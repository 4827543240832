<template>
    <div class="event">
      <h1 class="c-view-h1">{{ headline }}</h1>
      <div class="flex flex-wrap">
        <Event v-for="event in filteredEvents" :key="event.id" :event="event" />
      </div>
    </div>
</template>

<script>
    import Event from "@/components/Admin/Events/Event.vue";

    export default {
        name: 'event',
      components: {
          Event
      },
      data() {
          return {
          }
      },
      async beforeMount(){
          await this.$store.getEvents()
      },
      computed: {
          headline() {
            return this.$route.params.type === 'archive' ? 'Archiv' : 'Veranstaltungen';
          },
          filteredEvents(){
            let status = 'ACTIVE'
            if(this.$route.params.type === 'archive') status = 'ARCHIVE'
            return this.$store.state.events.filter(e => e.status === status)
          }
      }
    }
</script>

<style>


</style>
