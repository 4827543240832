<template>
  <div class="max-w-sm w-full sm:mr-6 rounded bg-white overflow-hidden shadow-lg mt-6">
    <div class="px-6 py-4">
      <div class="text-gray-900 font-bold text-xl mb-2">{{event.name}}</div>
      <p class="text-gray-700 text-base">
        {{ event.internalNotes }}
      </p>
    </div>
    <div class="px-6 pt-4 pb-4 text-right">
      <router-link :to="'/admin/event/'+event.id" v-if="$route.name === 'AdminEvents'">
        <a class="p-2"><i class="fal fa-edit"></i></a>
      </router-link>
    </div>

  </div>
</template>

<script>

// import Button from '@/components/ui/Button'

export default {
  name: 'Event',
  components: {
    // Button
  },
  props: {
    event: Object
  },
  data() {
    return {
      int: 0
    }
  }
};
</script>
